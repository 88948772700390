.list-container {
  justify-content: normal;
  background-color: var(--ion-color-light);
}
.punishment-list {
  margin-left: auto;
  margin-right: auto;

  width: 90%;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: transparent;
}

.punish-item {
  // margin-top: 10px;
  // padding: 10px;
  // border: 2px solid red;
  // height: 60px;
  // display: flex;

  border-radius: 10px;
  margin-top: 15px;
  --min-height: 70px;
  --padding-end: 15px;
  --padding-start: 17px;
}

.item-icon {
  color: #1e92f4;
}
