.header{
	font-size: 25px;
    color: var(--ion-color-primary);
    font-weight: 700;
    margin: auto;
    margin-bottom: 11px;
    width: fit-content;
	display: block;
    margin-top: 10px;
}

.image{
	width: 150px;
	margin: auto;
	margin-top: 15px;
	
}
.container.center {
	position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
	width: 95%;
}
