.make-notifaction-button {
  width: 80%;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  margin-bottom: 15px;
}
.modal-notifaction-button{
    width: 100%;
    height: 50px;
    margin-bottom: 20px;
    
}
.choose-type-button{
  width: 150px;
}
.damaged-input{
  height: 175px;
}
.damaged-textarea{
  --background: #1e1e1e;
  --color: #fff;
  // --padding-end: 10px;
  // --padding-start: 10px;
  --placeholder-color: #ddd;
  --placeholder-opacity: 0.8;
  padding: 20px;
  // border: 1px solid rgb(59, 59, 59);
  // box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  // height: 150px;
  
}