.salary-list-container {
  .month-name {
    color: var(--ion-color-dark);
  }
  justify-content: normal;
  background-color: var(--ion-color-light);
  .punishment-list {
    margin-left: auto;
    margin-right: auto;

    padding-top: 0px;
    padding-bottom: 0px;
    background-color: transparent;
  }

  .salary-item {
    border-radius: 10px;
    // margin-top: 15px;
    --min-height: 70px;
    // --padding-end: 5px;
    // --padding-start: 17px;
  }

  .month-name {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
