.menu-item{
  margin-top: 10px;
}



@keyframes athlete-animation {
  0%   {transform: scale(0.95)}
  50%  {transform: scale(1)}
  100% {transform: scale(0.95)}
}