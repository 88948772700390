.select-type-item{
  
  --placeholder-opacity: 1;
  
  text-align: center;
  
  padding-right: 30px;
}




